<template>
  <div class="flex m-4 space-x-4">
    <div class="flex flex-col shadow-lg rounded overflow-hidden">
      <div class="w-full text-center bg-primary text-white p-4 text-xl font-bold">Shipping</div>
      <div class="p-4 space-y-2">
        <div class="flex align-middle items-center">
          <div>$0.01 to</div>
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="priceOne"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
          will be
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="shippingOne"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
        </div>
        <div class="flex align-middle items-center">
          ${{ priceOne }} to
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="priceTwo"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
          will be
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="shippingTwo"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
        </div>
        <div class="flex align-middle items-center">
          ${{ priceTwo }} to
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="priceThree"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
          will be
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="shippingThree"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
        </div>
        <div class="flex align-middle items-center">
          ${{ priceThree }} to
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="priceFour"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
          will be
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="shippingFour"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
        </div>
        <div class="flex align-middle items-center">
          anything larger than ${{ priceFour }} will be
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              v-model="shippingFive"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4"
              id=""
            />
          </div>
        </div>
      </div>

      <div class="justify-between flex p-4">
        <button @click="getShippingCosts" class="rounded bg-white border border-gray-300 px-2">
          Refresh
        </button>

        <button @click="saveShippingCosts" class="btn-primary rounded">Save</button>
      </div>
    </div>
    <div class="flex flex-col shadow-lg rounded overflow-hidden">
      <div class="w-full text-center bg-primary text-white p-4 text-xl font-bold">Tax Rate</div>
      <div class="p-4 space-y-2">
        <div class="flex align-middle items-center">
          Tax Rate in the form of 0.0775 for 7.75%
          <div class="relative">
            <input
              type="number"
              v-model="tax_rate"
              class="border mx-2 rounded border-gray-300 focus:border-blue-500 sm:text-sm pl-4 no-arrows"
              step="0.0001"
              min="0"
              max="1"
              placeholder="0.0775"
              id=""
            />
          </div>
        </div>
      </div>

      <div class="justify-end flex px-4">
        <button @click="saveTaxRate" class="btn-primary rounded">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      priceOne: 0,
      shippingOne: 0,
      priceTwo: 0,
      shippingTwo: 0,
      priceThree: 0,
      shippingThree: 0,
      priceFour: 0,
      shippingFour: 0,
      shippingFive: 0,
      tax_rate: 0,
    };
  },
  methods: {
    saveShippingCosts() {
      this.$store.dispatch('order/saveShippingCosts', [
        { price: this.priceOne, cost: this.shippingOne },
        { price: this.priceTwo, cost: this.shippingTwo },
        { price: this.priceThree, cost: this.shippingThree },
        { price: this.priceFour, cost: this.shippingFour },
        { price: null, cost: this.shippingFive },
      ]);
    },
    getShippingCosts() {
      const val = this.$store.getters['order/getShippingCosts'];
      this.priceOne = val[0].price;
      this.shippingOne = val[0].cost;
      this.priceTwo = val[1].price;
      this.shippingTwo = val[1].cost;
      this.priceThree = val[2].price;
      this.shippingThree = val[2].cost;
      this.priceFour = val[3].price;
      this.shippingFour = val[3].cost;
      this.shippingFive = val[4].cost;
    },

    saveTaxRate() {
      this.$store.dispatch('order/saveTaxRate', { tax: this.tax_rate });
    },
  },
  mounted() {
    this.getShippingCosts();
    this.tax_rate = this.$store.getters['order/getTaxRate'];
  },
};
</script>

<style></style>
